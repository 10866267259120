<template>
    <el-dialog :title="$t('project.component_TaskAssignDialog_dialog_title')" :visible.sync="show" destroy-on-close width="1280px" top="20px" @closed="onClosed">
        <div class="task-assign-main" v-if="task">
            <div class="task-info">
                <div class="flex-1 margin-r-10 overflow-y-auto" style="max-height: 45px;">
                    <template v-for="(ta, taIndex) in task">
                        <div class="flex flex-align-center margin-b-5" :key="taIndex">
                            <div class="task-type-icon">
                                <svg-icon name="ib-task-trans" v-if="ta.taskType === 'TRANSLATION'"></svg-icon>
                                <svg-icon name="ib-task-review" v-if="ta.taskType === 'REVISION'"></svg-icon>
                                <svg-icon name="ib-task-qa" v-if="ta.taskType === 'QUALITY_CONTROL'"></svg-icon>
                            </div>
                            <div class="flex margin-x-10 fonts-15">
                                <div class="text-omission">{{$t('project.component_TaskAssignDialog_dialog_tips_1', {size: ta.sentenceRange})}}</div>
                                <div class="flex-1 margin-l-10">
                                    {{ta.typeDesc}}<span class="margin-l-5">{{ $t('project.component_TaskAssignDialog_dialog_tips_4', {size: ta.sourceWords}) }}</span>
                                </div>
                            </div>
                            <language-direction :source="ta.sourceLang" :target="ta.targetLang" type="half"></language-direction>
                        </div>
                    </template>
                </div>
                <div class="flex flex-align-center flex-justify-center">
                    <div class="flex flex-align-center" >
                        <div class="task-type-icon">
                            <svg-icon name="ib-task-user"></svg-icon>
                        </div>
                        <!-- <el-avatar :size="34" :src="active.avatar" icon="el-icon-user"></el-avatar> -->
                        <div class="margin-l-10">
                            <div class="fonts-15">
                                {{$t('project.component_TaskAssignDialog_dialog_tips_5')}}
                                <template v-if="active.length > 0">
                                    <template v-for="(assignee, assigneeIndex) in active">
                                        <template v-if="assigneeIndex < 2">{{assignee.alias || assignee.assigneeAlias}}</template>
                                        <template v-if="assigneeIndex < active.length - 1 && assigneeIndex < 1 ">,</template>
                                    </template>
                                    <span class="margin-l-5" v-if="active.length > 2">{{$t('project.component_TaskAssignDialog_dialog_tips_6', {size: active.length})}}</span>
                                </template>
                                <template v-else>—</template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-1 text-right">
                    <template v-if="userInfo.internal">
                        <el-badge :value="filterCount" is-dot :hidden="filterCount === 0" class="filter-badge margin-r-10">
                            <el-button round size="medium" @click="showFilter = !showFilter" class="fonts-18" style="padding: 8px"><svg-icon name="ib-funnel"></svg-icon></el-button>
                        </el-badge>
                    </template>
                    <el-input v-model="keyword" size="medium" suffix-icon="el-icon-search" clearable round :placeholder="`${$t('project.component_TaskAssignDialog_dialog_input_placeholder_1')}${userInfo.internal? $t('project.component_TaskAssignDialog_dialog_input_placeholder_2') : $t('project.component_TaskAssignDialog_dialog_input_placeholder_3')}${$t('project.component_TaskAssignDialog_dialog_input_placeholder_4')}`" @input="searchInputChange" style="width: 260px;"></el-input>
                </div>
            </div>
            <template v-if="userInfo.internal">
                <div class="filter-content" v-if="showFilter">
                    <el-form ref="filterForm" inline size="medium" label-width="70px" label-position="left" style="width: 880px;">
                        <el-row :gutter="10">
                            <el-col :span="24">
                                <div class="flex flex-align-center margin-b-10">
                                    <div class="flex flex-align-center">
                                        <el-checkbox v-model="isPVL" :label="$t('project.component_TaskAssignDialog_dialog_checkbox_label_1')"></el-checkbox>
                                        <el-tooltip :content="$t('project.component_TaskAssignDialog_dialog_tips_7')" placement="top" class="margin-l-5">
                                            <svg-icon name="ib-question"></svg-icon>
                                        </el-tooltip>
                                    </div>
                                    <div class="flex flex-align-center margin-l-20">
                                        <el-checkbox v-model="internalOnly" :label="$t('project.component_TaskAssignDialog_dialog_checkbox_label_2')"></el-checkbox>
                                        <el-tooltip :content="$t('project.component_TaskAssignDialog_dialog_tips_14')" placement="top" class="margin-l-5">
                                            <svg-icon name="ib-question"></svg-icon>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="10" v-if="isPVL">
                            <el-col :span="12">
                                <el-form-item :label="$t('project.component_TaskAssignDialog_dialog_form_item_1_label')" class="margin-b-10">
                                    <span class="margin-r-5">{{$t('project.component_TaskAssignDialog_dialog_tips_8')}}</span>
                                    <el-select v-model="serviceLine" @change="serviceLineSelectChange" size="medium" :placeholder="$t('project.component_TaskAssignDialog_dialog_form_item_placeholder_1')" style="width: 322px;">
                                        <el-option v-for="item in serviceLineOptions" :key="item.value" :label="item.text" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$t('project.component_TaskAssignDialog_dialog_form_item_2_label')" class="margin-b-10">
                                    <span class="margin-r-5">{{$t('project.component_TaskAssignDialog_dialog_tips_8')}}</span>
                                    <el-select v-model="customers" size="medium" multiple collapse-tags :placeholder="$t('project.component_TaskAssignDialog_dialog_form_item_placeholder_1')" clearable style="width: 322px;">
                                        <el-option v-for="item in customerOptions" :key="item.value" :label="item.text" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="10">
                            <el-col :span="12">
                                <el-form-item :label="$t('project.component_TaskAssignDialog_dialog_form_item_3_label')" class="margin-b-10">
                                    <span class="margin-r-5">{{$t('project.component_TaskAssignDialog_dialog_tips_8')}}</span>
                                    <el-select v-model="taskType" size="medium" :placeholder="$t('project.component_TaskAssignDialog_dialog_form_item_placeholder_1')" clearable style="width: 322px;">
                                        <el-option v-for="item in taskTypeOptions" :key="item.value" :label="item.text" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$t('project.component_TaskAssignDialog_dialog_form_item_4_label')" class="margin-b-10">
                                    <span class="margin-r-5">{{$t('project.component_TaskAssignDialog_dialog_tips_8')}}</span>
                                    <el-select v-model="unit" size="medium" multiple collapse-tags :placeholder="$t('project.component_TaskAssignDialog_dialog_form_item_placeholder_1')" clearable style="width: 322px;">
                                        <el-option v-for="item in unitOptions" :key="item.value" :label="item.text" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="10">
                            <el-col :span="12">
                                <el-form-item :label="$t('project.component_TaskAssignDialog_dialog_form_item_5_label')" class="margin-b-10">
                                    <span class="margin-r-5">{{$t('project.component_TaskAssignDialog_dialog_tips_8')}}</span>
                                    <el-select v-model="field" size="medium" multiple collapse-tags :placeholder="$t('project.component_TaskAssignDialog_dialog_form_item_placeholder_1')" clearable style="width: 322px;">
                                        <el-option v-for="item in fieldOptions" :key="item.value" :label="item.text" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$t('project.component_TaskAssignDialog_dialog_form_item_6_label')" class="margin-b-10">
                                    <span class="margin-r-5">{{$t('project.component_TaskAssignDialog_dialog_tips_8')}}</span>
                                    <el-select v-model="currency" size="medium" multiple collapse-tags :placeholder="$t('project.component_TaskAssignDialog_dialog_form_item_placeholder_1')" clearable style="width: 322px;">
                                        <el-option v-for="item in currencyOptions" :key="item.value" :label="item.text" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="10">
                            <el-col :span="12">
                                <el-form-item :label="$t('project.component_TaskAssignDialog_dialog_form_item_7_label')" class="margin-b-10">
                                    <span class="margin-r-5">{{$t('project.component_TaskAssignDialog_dialog_tips_8')}}</span>
                                    <el-select v-model="catTool" size="medium" multiple collapse-tags :placeholder="$t('project.component_TaskAssignDialog_dialog_form_item_placeholder_1')" clearable style="width: 322px;">
                                        <el-option v-for="item in catToolOptions" :key="item.value" :label="item.text" :value="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item :label="$t('project.component_TaskAssignDialog_dialog_form_item_8_label')" class="margin-b-10">
                                    <span class="margin-r-5">{{$t('project.component_TaskAssignDialog_dialog_tips_9')}}</span>
                                    <el-input-number v-model="priceMin" size="medium" controls-position="right" :min="0" :max="10000" :step="1" :placeholder="$t('project.component_TaskAssignDialog_dialog_input_placeholder_5')" style="width: 154px"></el-input-number>
                                    <span class="margin-x-4">-</span>
                                    <el-input-number v-model="priceMax" size="medium" controls-position="right" :min="0" :max="10000" :step="1" :placeholder="$t('project.component_TaskAssignDialog_dialog_input_placeholder_6')" style="width: 154px"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <div class="margin-b-10">
                        <el-button round size="medium" @click="showFilter = !showFilter" class="margin-b-8">{{$t('project.component_TaskAssignDialog_dialog_btn_1')}}</el-button><br>
                        <el-button type="primary" round size="medium" @click="getFilterCount(); pageNumber=1;initMemberList()" :loading="searchLoading">{{$t('project.component_TaskAssignDialog_dialog_btn_2')}}</el-button>
                        <el-button type="text" @click="resetFilterForm(true)">{{$t('project.component_TaskAssignDialog_dialog_btn_3')}}</el-button>
                    </div>
                </div>
            </template>
            <div class="flex" v-if="!userInfo.internal">
                <div class="flex flex-align-center">
                    <span class="margin-r-5">{{$t('project.component_TaskAssignDialog_dialog_tips_10')}}</span>
                    <el-input-number v-model="extraRate" @change="handleExtraRateChange" :min="0" :step="1" :precision="0" controls-position="right" size="mini"></el-input-number>
                    <el-tooltip :content="$t('project.component_TaskAssignDialog_dialog_tips_11')" placement="top">
                        <svg-icon name="ib-question" class="margin-l-5"></svg-icon>
                    </el-tooltip>
                </div>
            </div>
            <!-- @filter-change="filterChange" -->
            <el-table ref="tableList" :data="list" row-key="uniqueId" :cell-class-name="tableRowClassName" @selection-change="handleTableSelectionChange" @row-click="rowClickEvent" @sort-change="sortChange" width="100%">
                <template v-if="!userInfo.internal">
                    <el-table-column type="selection" width="55" :reserve-selection='true'></el-table-column>
                </template>
                <el-table-column :label="$t('project.component_TaskAssignDialog_dialog_table_column_1')" fixed="" :min-width="userInfo.internal ? 120 : ''">
                    <template slot-scope="scope">
                        <el-tooltip placement="top-start">
                            <div class="line-height-1.5" slot="content">
                                <p>{{scope.row.assigneeAlias}}</p>
                                <template v-for="(score, scoreIndex) in scope.row.scores">
                                    <p :key="scoreIndex">{{score.name}}：{{score.score}}</p>
                                </template>
                            </div>
                            <span>{{scope.row.assigneeAlias}}</span>
                        </el-tooltip>
                        
                    </template>
                </el-table-column>
                <template v-if="userInfo.internal">
                    <el-table-column :label="$t('project.component_TaskAssignDialog_dialog_table_column_2')" width="90" prop="role" column-key="role"></el-table-column> <!-- :filters="roleOptions" -->
                    <el-table-column :label="$t('project.component_TaskAssignDialog_dialog_table_column_3')" min-width="70" prop="businessType" column-key="businessType"></el-table-column>
                    <el-table-column :label="$t('project.component_TaskAssignDialog_dialog_table_column_13')" min-width="80" prop="sourceLang" show-overflow-tooltip></el-table-column>
                    <el-table-column :label="$t('project.component_TaskAssignDialog_dialog_table_column_14')" min-width="80" prop="targetLang" show-overflow-tooltip></el-table-column>
                    <el-table-column :label="$t('project.component_TaskAssignDialog_dialog_table_column_4')" min-width="120" prop="field" show-overflow-tooltip column-key="field"></el-table-column> <!-- :filters="fieldOptions" -->
                    <el-table-column :label="$t('project.component_TaskAssignDialog_dialog_table_column_5')" min-width="80" prop="price"></el-table-column> <!-- sortable="custom" -->
                    <el-table-column :label="$t('project.component_TaskAssignDialog_dialog_table_column_6')" min-width="140" prop="unit" show-overflow-tooltip column-key="unit"></el-table-column> <!-- :filters="unitOptions" -->
                    <el-table-column :label="$t('project.component_TaskAssignDialog_dialog_table_column_7')" min-width="100" prop="currency" show-overflow-tooltip column-key="currency"></el-table-column> <!-- :filters="currencyOptions" -->
                    <!-- <el-table-column label="CAT工具" min-width="140" prop="catTools" show-overflow-tooltip column-key="catTools"></el-table-column> -->
                </template>
                <template v-else>
                    <el-table-column :label="$t('project.component_TaskAssignDialog_dialog_table_column_8')" prop="role" column-key="role"></el-table-column>
                    <el-table-column :label="$t('project.component_TaskAssignDialog_dialog_table_column_9')" min-width="120">
                        <template slot-scope="scope">
                            <template v-for="(item, index) in scope.row.quotations">
                                <div :key="index">
                                    <span class="color-999" v-if="scope.row.quotations.length > 1">{{index + 1}}.</span> 
                                    {{item.taskTypeDesc}}[{{item.startSentenceIndex}}-{{item.endSentenceIndex}} {{$t('project.component_TaskAssignDialog_dialog_tips_12')}}] - {{item.fileName}}
                                </div>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('project.component_TaskAssignDialog_dialog_table_column_10')" min-width="70">
                        <template slot-scope="scope">
                            <template v-for="(item, index) in scope.row.quotations">
                                <div :key="index">
                                    <span class="color-999" v-if="scope.row.quotations.length > 1">{{index + 1}}. </span>
                                    {{item.price}}/{{item.unitDesc}}
                                </div>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('project.component_TaskAssignDialog_dialog_table_column_11')" min-width="70">
                        <template slot-scope="scope">
                            <template v-for="(item, index) in scope.row.quotations">
                                <div :key="index">
                                    <span class="color-999" v-if="scope.row.quotations.length > 1">{{index + 1}}. </span>
                                    {{item.payWordCount}}
                                </div>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('project.component_TaskAssignDialog_dialog_table_column_12')" width="120">
                        <template slot-scope="scope">
                            <template v-for="(item, index) in scope.row.quotations">
                                <div :key="index">
                                    <span class="color-999" v-if="scope.row.quotations.length > 1">{{index + 1}}. </span>
                                    {{item.totalPrice}}
                                </div>
                            </template>
                        </template>
                    </el-table-column>
                </template>
                <!-- <template v-if="!userInfo.internal">
                    <el-table-column label="手机号" :width="userInfo.internal ? 145:''" prop="phone" fixed="right"></el-table-column>
                    <el-table-column label="邮箱" :width="userInfo.internal ? 180: ''" prop="mail" fixed="right" show-overflow-tooltip></el-table-column>
                </template> -->
                <!-- <template v-if="userInfo.internal">
                    <el-table-column label="系统编码" width="100" prop="sysCode" fixed="right"></el-table-column>
                </template> -->
            </el-table>
            <div class="pagination-content" style="margin: 20px 0 0 0;" v-if="list.length > 0 && total > pageSize">
                <el-pagination 
                    background 
                    @current-change="handleCurrentChange"
                    layout="total, prev, pager, next"
                    :current-page="pageNumber"
                    :page-size="pageSize"
                    :total="total">
                </el-pagination>
            </div>
            
        </div>
        <div slot="footer" class="text-center">
            <el-button plain round @click="show = false">{{$t('project.component_TaskAssignDialog_dialog_btn_4')}}</el-button>
            <el-button type="primary" round :loading="loading" @click="submitAssignEvent">{{$t('project.component_TaskAssignDialog_dialog_btn_5')}}</el-button>
            <div class="fonts-12 margin-t-15 color-808080">{{$t('project.component_TaskAssignDialog_dialog_tips_13')}}</div>
        </div>
        
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import tools from '@/utils/tools';
import { docApi } from '@/utils/api';
import SvgIcon from '../../../components/SvgIcon.vue';
import LanguageDirection from '@/components/LanguageDirection.vue';
export default {
	components: { SvgIcon, LanguageDirection, },
    name: 'TaskAssignDialog',
    data() {
        return {
            show: false,
            loading: false,
            task: null,
            taskIds: [],
            doc: null,

            active: [],
            list: [],
            keyword: '',
            order: '',
            role: '',
            roleOptions: [],
            field: '',
            fieldOptions: [],
            unit: '',
            unitOptions: [],
            currency: '',
            currencyOptions: [],
            taskType: '',
            taskTypeOptions: [],
            catTool: '',
            catToolOptions: [],
            priceMin: '',
            priceMax: '',
            user: {},
            isPVL: true,
            internalOnly: false,
            serviceLine: '',
            serviceLineOptions: [],
            customers: [],
            customerOptions: [],

            pageNumber: 1,
            pageSize: 12,
            total: 0,

            searchLoading: false,
            searchTimer: null,
            showFilter: true,
            filterCount: 0,
            selectedIds: [],
            selectedData: [],
            extraRate: 0,
        }
    },
    computed: {
        ...mapState({
            activeOrg: state => state.org.activeOrg,
            userInfo: state => state.user.userData,
        }),
    },
    methods: {
        setShow(data, is) {
            this.task = data.task;
            console.log('task::', this.task)
            let assigneeList = [];
            data.task.map(item => {
                assigneeList = assigneeList.concat(item.assignee);
            });
            this.active = assigneeList;
            this.selectedData = assigneeList;
            // console.log('xxsdsd::', data, 'active::', this.active);
            
            this.taskType = data.task[0].taskType;
            this.taskIds = data.ids;
            this.doc = data.doc;
            this.show = is;
            if (this.userInfo.internal) {
                this.serviceLine = data.doc.extraData.customerInfo ? data.doc.extraData.customerInfo.serviceLine : '';
                this.customers = data.doc.extraData.customerInfo ? [data.doc.extraData.customerInfo.code] : [];
                this.initFilterOptions();
                this.initCustomerOptions('serviceLine', this.serviceLine);
            }
            this.initMemberList();
        },
        onClosed() {
            this.active = [];
            this.list = [];
            this.keyword = '';
            this.order = '';
            this.role = '';
            this.resetFilterForm(false);
            this.user = {};
            this.pageNumber = 1;
            this.total = 0;
            this.showFilter = true;
            clearTimeout(this.searchTimer);
            this.searchTimer = null;
        },
        resetFilterForm(reload) {
            this.field = '';
            this.unit = '';
            this.currency = '';
            this.taskType = '';
            this.catTool = '';
            this.priceMin = '';
            this.priceMax = '';
            this.filterCount = 0;
            this.customers = [];
            if(reload) {
                this.pageNumber = 1;
                this.initMemberList();
            }
        },
        initMemberList() {
            let url = docApi.queryAssignTableList;
            let postData = {
                pageSize: this.pageSize,
                pageNo: this.pageNumber,
                orgId: this.activeOrg.organizationId,
                teamId: this.doc.team.teamId,
                from: this.task[0].sourceLang,
                to: this.task.map(item => item.targetLang),
                taskType: this.task[0].type,
                projectId: this.doc.projectId,
                assignmentIds: this.task.map(item => item.assignmentId),
                extraPrice: this.extraRate,
            };
            if(this.keyword) {
                postData['keyword'] = this.keyword;
            }
            if(this.role.length) {
                postData['role'] = this.role;
            }
            if(this.unit.length) {
                postData['unit'] = this.unit;
            }
            if(this.currency.length) {
                postData['currency'] = this.currency;
            }
            if(this.field.length) {
                postData['field'] = this.field;
            }
            if(this.order) {
                postData['order'] = this.order;
            }
            if(this.taskType) {
                postData['taskType'] = this.taskType;
            }
            if(this.catTool) {
                postData['catTools'] = this.catTool;
            }
            if(this.priceMin || this.priceMax) {
                if(this.priceMin !== '') {
                    postData['priceMin'] = this.priceMin;
                }
                if(this.priceMax !== '') {
                    postData['priceMax'] = this.priceMax;
                }
            }
            if(this.userInfo.internal) {
                postData['isPVL'] = this.isPVL;
                postData['internalOnly'] = this.internalOnly;
                if(this.serviceLine) {
                    postData['serviceLine'] = this.serviceLine;
                }
                if(this.customers && this.customers.length) {
                    postData['customerCode'] = this.customers;
                }
            }
            this.searchLoading = true;
            this.$ajax.post(url, postData).then(async res => {
                if (res.status == 200) {
                    this.list = res.data.data;
                    this.total = res.data.total;
                    if (!this.userInfo.internal) {
                        this.$refs.tableList.clearSelection();
                        await tools.sleep(100);
                        let _ids = this.active.map(item => item.uniqueId);
                        this.list.forEach(item => {
                            if(_ids.includes(item.uniqueId)) {
                                this.$refs.tableList.toggleRowSelection(item, true);
                            }
                        })
                    }
                }                
            }).finally(()=>{
                this.searchLoading = false;
            });
        },
        searchInputChange() {
            let self = this;
            if(this.searchTimer) {
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(() => {
                self.initMemberList();
            }, 500);
        },
        handleCurrentChange(val) {
            this.pageNumber = val;
            this.initMemberList();
        },
        initFilterOptions() {
            let url = `${docApi.queryAssignTableFilterOptions}?teamId=${this.doc.team.teamId}`;
            this.$ajax.get(url).then(res => {
                if (res.status == 200) {
                    this.roleOptions = res.data.role;
                    this.fieldOptions = res.data.field;
                    this.unitOptions = res.data.unit;
                    this.currencyOptions = res.data.currency;
                    this.taskTypeOptions = res.data.taskType;
                    this.catToolOptions = res.data.catTool;
                    this.serviceLineOptions = res.data.serviceLine;
                }
            });
        },
        initCustomerOptions (type, value) {
            let url = this.$ajax.format(docApi.queryAssignTableSubFilterOptions, {type}) + '?value=' + (value ? value : '');
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    let _list = res.data?.options;
                    if(this.doc.extraData.customerInfo) {
                        let _fList = _list.filter(item => item.value == this.doc.extraData.customerInfo.code);
                        if(_fList.length === 0) {
                            _list.push({value: this.doc.extraData.customerInfo.code, text: this.doc.extraData.customerInfo.name})
                        }
                    }
                    this.customerOptions = _list;
                }
            })
        },
        serviceLineSelectChange(val) {
            this.customers = [];
            this.initCustomerOptions('serviceLine', val);
        },
        submitAssignEvent() {
            // console.log(this.selectedData);
            if(this.selectedData.length === 0) {
                this.$message.error(this.$t('project.component_TaskAssignDialog_dialog_method_submitAssignEvent_message_1'));
                return;
            }
            let url = docApi.batchSetAssignmentTask;
            let assignments = [];
            this.selectedData.map(item => {
                assignments.push({
                    assigneeMemberId: item.assigneeId || item.memberId,
                    allocationDetails: 
                        !item.quotations ? this.task.map(t => {return {assignmentId: t.taskId, teamQuotationId: ''}}) : 
                        item.quotations.map(qu => {
                            return {
                                assignmentId: qu.assignmentId,
                                teamQuotationId: qu.quotationId,
                                initPrice: qu.price + '',
                                priceWordCount: qu.payWordCount,
                                additionalRates: this.extraRate,
                                totalPrice: qu.totalPrice + '',
                                priceUnit: qu.unit,
                            }
                        }),
                    rowId: this.active[0].rowId || '',
                    sysCode: this.active[0].sysCode || '',
                })
            })
            this.loading = true;
            this.$ajax.post(url, {assignments}).then(res => {
                if(res.status === 200) {
                    this.$emit('change', {docId: this.task[0].documentId});
                }
            }).finally(() => {
                this.loading = false;
                this.show = false;
            })
            
        },
        handleTableSelectionChange(val) {
            this.selectedData = val;
            this.active = val;
            this.selectedIds = val.map(item => item.uniqueId);
            // console.log(val, this.selectedIds, this.selectedData);
        },
        tableRowClassName({row, rowIndex}) {
            if(!this.userInfo.internal) return '';
            if(this.active.length && row.uniqueId === this.active[0].uniqueId) {
                return 'active-row';
            }
            return '';
        },
        rowClickEvent(row, column, event) {
            if(!this.userInfo.internal) return;
            if (!row.enable) {
                this.$message.warning(this.$t('project.component_TaskAssignDialog_dialog_method_rowClickEvent_message_1'));
                return;
            }
            this.active = [{...row, memberId: row.assigneeId, alias: row.assigneeAlias, avatar: row.assigneeAvatar+`&_cTime=${new Date().getTime()}`}];
            this.selectedData = this.active;
        },
        handleExtraRateChange() {
            this.searchInputChange();
        },
        filterChange(filters) {
            let key = Object.keys(filters)[0];
            this[key] = filters[key];
            this.initMemberList();
        },
        sortChange({ column, prop, order }) {
            this.order = order === 'ascending' ? 1 : order === 'descending' ? 2 : '';
            this.initMemberList();
        },
        getFilterCount() {
            let count = 0;
            // if(this.taskType) count += 1;
            if(this.unit && this.unit.length) count += 1;
            if(this.field && this.field.length) count += 1;
            if(this.currency && this.currency.length) count += 1;
            if(this.catTool && this.catTool.length) count += 1;
            if(this.priceMin || this.priceMax) count += 1;
            if(this.userInfo.internal && this.isPVL) count += 1;
            this.filterCount = count;
        }
    },
    beforeDestroy() {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
    }
}
</script>
<style lang="scss" scoped>
.task-assign-main {
    ::v-deep .el-table {
        th {
            padding: 8px 0;
            font-size: 15px;
            color: #999999;
        }
        td {
            padding: 13.45px 0;
        }
        .active-row {
            background-color: #E5F2E6 !important;
        }
    }
    .task-info {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        margin-bottom: 20px;
        ::v-deep .el-input__inner {
            border-radius: 20px;
        }
        .task-type-icon{
            width: 24px;
            height: 24px;
            border-radius: 50px;
            box-sizing: border-box;
            border: 1px solid #0000001A;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
        }
    }
    .filter-content {
        display: flex;
        align-items: flex-end;
        ::v-deep .el-select {
            .el-tag {
                max-width: 180px;
            }
        }
    }
    .filter-badge {
        ::v-deep .el-badge__content.is-dot {
            top: 5px;
            right: 8px;
        }
    }
}
</style>
